import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Поле ввода чисел с фильтрацией и форматированным отображением значения и маской.
 *
 * @param value        - Исходное значение.
 * @param prefix       - Префикс, отображаемый до вводимого значения.
 * @param suffix       - Суффикс, отображается после вводимого значения.
 * @param placeholder  - Подсказка, отображается когда значение ещё не введено.
 * @param help         - Помощь, отображается когда введено не верное значение или поле уже затронуто, но значения нет.
 * @param isInvalid    - Индикатор - проверка значения выявила ошибку.
 * @param isTouched    - Индикатор - поле было затронуто.
 * @param isReadonly   - Режим "только для чтения" - значение отображается, без возможности редактирования.
 * @return valueChange - Канал изменённого значения.
 */
@Component({
  selector: 'app-input-number',
  templateUrl: './app-input-number.component.html',
  styleUrl: './app-input-number.component.scss'
})
export class AppInputNumberComponent {
  @Input() public value: number | null;
  @Input() public prefix: string;
  @Input() public suffix: string;
  @Input() public placeholder: string;
  @Input() public help: string[];
  @Input() public isInvalid: boolean;
  @Input() public isTouched: boolean;
  @Input() public isReadonly: boolean;
  @Output() public valueChange: EventEmitter<number | null>;

  /** Конструктор. */
  constructor() {
    [this.value, this.prefix, this.suffix, this.placeholder, this.help] = [null, '', '', '', []];
    [this.isInvalid, this.isTouched] = [false, false];
    this.isReadonly = false;
    this.valueChange = new EventEmitter<number | null>();
  }

  /** Добытчик значения с переводом в требуемую точность. */
  public get valueNumber(): number | null {
    if (this.value === null) return null;
    return (this.value) / 1000;
  }

  /** Установщик значения с переводом в требуемую точность. */
  public set valueNumber(v: number) {
    this.value = v * 1000;
  }

  /** Сброс значения. */
  public async valueReset(): Promise<void> {
    this.value = null;
    this.valueChange.next(this.value);
  }

  /** Передача результата. */
  public async onChange(_e: Event): Promise<void> {
    this.valueChange.next(this.value);
  }
}
