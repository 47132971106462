import { inject, Injectable, OnDestroy } from '@angular/core';
import { EventManager } from "@angular/platform-browser";
import { Subject } from "rxjs";

import { TelegramWebApp, WebApp, PopupParams, ScanQrPopupParams } from "@m1cron-labs/ng-telegram-mini-app";
import { BiometricAuthenticateParams, BiometricRequestAccessParams } from "@m1cron-labs/ng-telegram-mini-app";

import { IButtonType, ICallbackEvents } from "./telegram.types";
import { TelegramEvents } from "./telegram.events";


// noinspection JSUnusedGlobalSymbols
/**
 * Сервис для работы с библиотекой телеграм интеграции с приложениями телеграм.
 * Основан на @m1cron-labs/ng-telegram-mini-app, предоставляет более удобный для использования программный интерфейс.
 */
@Injectable({
  providedIn: 'root',
})
export class TelegramService extends TelegramEvents implements OnDestroy {
  private readonly telegram$: WebApp;

  /** Конструктор. */
  constructor(
    eventManager: EventManager,
  ) {
    super(eventManager);
    this.telegram$ = inject(TelegramWebApp);
    (<IButtonType[]>['main', 'secondary', 'back', 'settings'])
      .forEach((t: IButtonType) => this.callbackEvents$.push(this.makeICallbackEvent(t)));
    // Инициализация библиотеки работы с телеграм мини апп API.
    this.telegram$.ready();
  }

  /** Деструктор. */
  ngOnDestroy(): void {
    this.close();
  }

  /** Создание объекта событий и подписки кнопки по типу кнопки. */
  override makeICallbackEvent(t: IButtonType): ICallbackEvents {
    let ret: ICallbackEvents;
    let key: string;

    key = this.makeRandomString();
    ret = {
      type: t,
      key: key,
      fn: this.makeCallback(key),
      sub: new Subject<never>(),
    }
    switch (t) {
      case "main":
        this.Api.MainButton.onClick(ret.fn);
        break;
      case "secondary":
        this.Api.SecondaryButton.onClick(ret.fn);
        break;
      case "back":
        this.Api.BackButton.onClick(ret.fn);
        break;
      case "settings":
        this.Api.SettingsButton.onClick(ret.fn);
        break;
    }

    return ret;
  }

  /** Поиск объекта событий и подписки кнопки по типу кнопки. */
  private getSubjectByType(t: IButtonType): Subject<never> {
    let ret: Subject<never>;
    this.callbackEvents$.forEach((evt: ICallbackEvents) => {
      if (evt.type === t) ret = evt.sub;
    });
    return ret!;
  }


  /** Вызывает закрытие телеграм мини апп. */
  public close(): void {
    this.telegram$.close();
  }

  /**
   * Объект библиотеки работы с телеграм мини апп API.
   */
  public get Api(): WebApp {
    return this.telegram$;
  }

  /** Подписка на событие клика по главной кнопке. */
  public get MainButtonOnClick(): Subject<never> {
    return this.getSubjectByType('main');
  }

  /** Подписка на событие клика по второй кнопке. */
  public get SecondaryButtonOnClick(): Subject<never> {
    return this.getSubjectByType('secondary');
  }

  /** Подписка на событие клика по кнопке назад. */
  public get BackButtonOnClick(): Subject<never> {
    return this.getSubjectByType('back');
  }

  /** Подписка на событие клика по кнопке настройки. */
  public get SettingsButtonOnClick(): Subject<never> {
    return this.getSubjectByType('settings');
  }

  /**
   * Открытие всплывающего окна и возвращение обещания.
   * Обещание будет вызвано после закрытия всплывающего окна.
   * @param popupParams - Параметры открываемого всплывающего окна.
   */
  public Popup(popupParams: PopupParams): Promise<string> {
    const id: string = this.makeRandomString();
    this.Api.showPopup(popupParams, this.makeCallback(id));
    return this.makePromiseString(id);
  }

  /**
   * Открытие счёта для оплаты по ссылке и возвращение обещания.
   * Мини-приложение получит сообщение о событии invoiceClosed, когда счет будет закрыт.
   * Так же, будет вызвано обещание, в которое будет передан статус счёта.
   * @param uri - Адрес счёта.
   * @constructor
   */
  public Invoice(uri: string): Promise<string> {
    const id: string = this.makeRandomString();
    this.Api.openInvoice(uri, this.makeCallback(id));
    return this.makePromiseString(id);
  }

  /**
   * Открывает всплывающее окно сканирования QR-кода, описываемое аргументом scanQrPopupParams и возвращает обещание.
   * Мини-приложение получит событие qrTextReceived, когда сканер распознает код с текстовыми данными.
   * Обещание будет вызвано с текстом из QR-кода.
   * @param text - Текст отображаемый в окне сканирования QR кода.
   * @constructor
   */
  public PopupScanQr(text?: string): Promise<string> {
    const id: string = this.makeRandomString();
    const params: ScanQrPopupParams = {text: text};
    this.Api.showScanQrPopup(params, this.makeCallback(id));
    return this.makePromiseString(id);
  }

  /**
   * Открывает всплывающее окно подтверждения с кнопками "ОК" и "Отмена" и возвращает обещание.
   * Обещание вызывается после закрытия всплывающего окна и возвращает булево значение "ложь" или "истина",
   * указывающее, нажал ли пользователь кнопку "ОК".
   * @param message - Текст, отображающийся ы окне подтверждения.
   */
  public PopupConfirm(message: string): Promise<boolean> {
    const id: string = this.makeRandomString();
    this.Api.showConfirm(message, this.makeCallback(id));
    return this.makePromiseBoolean(id);
  }

  /**
   * Открывает всплывающее окно подтверждения с кнопкой "Закрыть" и возвращает обещание.
   * Обещание вызывается после закрытия всплывающего окна.
   * @param message - Текст, отображающийся ы окне подтверждения.
   */
  public PopupAlert(message: string): Promise<void> {
    const id: string = this.makeRandomString();
    this.Api.showAlert(message, this.makeCallback(id));
    return this.makePromiseVoid(id);
  }

  /**
   * Запрос текста из буфера обмена и возвращение обещания.
   * Как только текст будет получен, будет вызвано обещание и передан полученный текст из буфера обмена.
   */
  public ClipboardTextRead(): Promise<string> {
    const id: string = this.makeRandomString();
    this.Api.readTextFromClipboard(this.makeCallback(id));
    return this.makePromiseString(id);
  }

  /**
   * Открывает нативное всплывающее окно с запросом разрешения у пользователя на отправку сообщений ботом и возвращает
   * обещание.
   * Обещание будет вызвано после закрытия всплывающего окна и вернёт булево значение "ложь" или "истина",
   * указывающее, предоставил ли пользователь этот доступ.
   */
  public WriteAccessRequest(): Promise<boolean> {
    const id: string = this.makeRandomString();
    this.Api.requestWriteAccess(this.makeCallback(id));
    return this.makePromiseBoolean(id);
  }

  /**
   * Открывает нативное всплывающее окно с запросом предоставления контакта пользователя и возвращает обещание.
   * Обещание будет вызвано после закрытия всплывающего окна и вернёт булево значение "ложь" или "истина",
   * указывающее, предоставил ли пользователь свой номер телефона.
   */
  public ContactRequest(): Promise<boolean> {
    const id: string = this.makeRandomString();
    this.Api.requestContact(this.makeCallback(id));
    return this.makePromiseBoolean(id);
  }

  /**
   * Запуск инициализации биометрии и возврат обещания.
   * Обещание будет вызвано после окончания инициализации биометрии.
   */
  public BiometricInit(): Promise<void> {
    const id: string = this.makeRandomString();
    this.Api.BiometricManager.init(this.makeCallback(id));
    return this.makePromiseVoid(id);
  }

  /**
   * Запрос разрешения на использование биометрических данных и возврат обещания.
   * Обещание будет вызвано и вернёт булево значение "ложь" или "истина", указывающее, предоставил ли пользователь
   * доступ или не предоставил.
   * @param reason - Строка, отображаемая пользователю и описывающая с какой целью запрашивается разрешение.
   */
  public BiometricAccessRequest(reason?: string): Promise<boolean> {
    const id: string = this.makeRandomString();
    const params: BiometricRequestAccessParams = {reason: reason};
    this.Api.BiometricManager.requestAccess(params, this.makeCallback(id));
    return this.makePromiseBoolean(id);
  }

  /**
   * Аутентификация пользователя с использованием биометрических данных и возврат обещания.
   * Обещание будет вызвано и вернёт одно из двух вариантов значения:
   * null                 - Если пользователь не прошёл успешную аутентификацию;
   * биометрический токен - Если пользователь успешно прошёл биометрическую аутентификацию;
   * @param reason - Строка, отображаемая пользователю и описывающая с какой целью запрашивается аутентификация.
   */
  public BiometricAuthenticate(reason?: string): Promise<string | null> {
    const id: string = this.makeRandomString();
    const params: BiometricAuthenticateParams = {reason: reason};
    this.Api.BiometricManager.authenticate(params, this.makeCallback(id));
    return this.makePromiseNilString(id);
  }

  /**
   * Обновление биометрического токена в защищённом хранилище устройства и возврат обещания.
   * Если передаётся токен равный пустой строке, это вызовет удаление токена в хранилище.
   * Обещание будет вызвано и вернёт булево значение "ложь" или "истина", указывающее, был ли обновлён токен.
   * @param token - Новое значение токена.
   */
  public BiometricTokenUpdate(token: string): Promise<boolean> {
    const id: string = this.makeRandomString();
    this.Api.BiometricManager.updateBiometricToken(token, this.makeCallback(id));
    return this.makePromiseBoolean(id);
  }

  /**
   * Сохранение ключ/значения в облачном хранилище телеграм и возврат обещания.
   * Количество хранимых пар ключ/значение может достигать до 1024 записи.
   * Обещание будет вызвано после завершения работы с хранилищем.
   * Если сохранение было прервано ошибкой, тогда будет создано исключение, в которое будет передана возникшая ошибка.
   * @param key   - строка, длинной от 1 до 128 символов. Допустимые символы: [A-Z, a-z, 0-9, _, -];
   * @param value - строка, длинной от 0 до 4096 символов;
   */
  public StorageSet(key: string, value: string): Promise<void> {
    const id: string = this.makeRandomString();
    this.Api.CloudStorage.setItem(key, value, this.makeCallback(id))
    return this.makePromiseVoidWithException(id);
  }

  /**
   * Получение значения из облачного хранилища по ключу и возврат обещания.
   * Обещание будет вызвано после завершения работы с хранилищем и вернёт запрашиваемое значение.
   * Если запрос был прерван ошибкой, тогда будет создано исключение, в которое будет передана возникшая ошибка.
   * @param key - строка, длинной от 1 до 128 символов. Допустимые символы: [A-Z, a-z, 0-9, _, -];
   */
  public StorageGet(key: string): Promise<string> {
    const id: string = this.makeRandomString();
    this.Api.CloudStorage.getItem(key, this.makeCallback(id))
    return this.makePromiseStringWithException(id);
  }

  /**
   * Получение множества значений с использованием указанных ключей и возврат обещания.
   * Обещание будет вызвано после завершения работы с хранилищем и вернёт запрашиваемое значение.
   * Если запрос был прерван ошибкой, тогда будет создано исключение, в которое будет передана возникшая ошибка.
   * @param keys - Ключи, чьи значения необходимо получить.
   */
  public StorageItems(...keys: string[]): Promise<string[]> {
    const id: string = this.makeRandomString();
    this.Api.CloudStorage.getItems(keys, this.makeCallback(id))
    return this.makePromiseStringArrayWithException(id);
  }

  /**
   * Получение ключей сохранённых в облачном хранилище и возврат обещания.
   * Обещание будет вызвано после завершения работы с хранилищем и вернёт множество ключей.
   * Если запрос был прерван ошибкой, тогда будет создано исключение, в которое будет передана возникшая ошибка.
   */
  public StorageKeys(): Promise<string[]> {
    const id: string = this.makeRandomString();
    this.Api.CloudStorage.getKeys(this.makeCallback(id))
    return this.makePromiseStringArrayWithException(id);
  }

  /**
   * Удаление ключ/значение по ключу из облачного хранилища и возврат обещания.
   * Обещание будет вызвано после завершения работы с хранилищем.
   * Если запрос был прерван ошибкой, тогда будет создано исключение, в которое будет передана возникшая ошибка.
   * @param key   - строка, длинной от 1 до 128 символов. Допустимые символы: [A-Z, a-z, 0-9, _, -];
   */
  public StorageDeleteKey(key: string): Promise<void> {
    const id: string = this.makeRandomString();
    this.Api.CloudStorage.removeItem(key, this.makeCallback(id))
    return this.makePromiseVoidWithException(id);
  }

  /**
   * Удаление ключ/значение по множеству ключей из облачного хранилища и возврат обещания.
   * Обещание будет вызвано после завершения работы с хранилищем.
   * Если запрос был прерван ошибкой, тогда будет создано исключение, в которое будет передана возникшая ошибка.
   * @param keys - Ключи, чьи значения необходимо удалить.
   */
  public StorageDeleteKeys(...keys: string[]): Promise<void> {
    const id: string = this.makeRandomString();
    this.Api.CloudStorage.removeItems(keys, this.makeCallback(id))
    return this.makePromiseVoidWithException(id);
  }
}
