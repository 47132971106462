import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { throwError, lastValueFrom, map, Observable, Subscriber } from 'rxjs';
import { catchError, debounceTime } from "rxjs/operators";

import { ApiConfig, IApiCreateResponse, IGetListOption, IGetListOptionFilter, IGetListOptionOrder } from './api.types';


@Injectable({
  providedIn: 'root',
})
export class ApiService {
  /**
   * Конструктор.
   * @param http        - HTTP клиент.
   * @param config      - Конфигурация API.
   */
  constructor(
    protected http: HttpClient,
    protected config: ApiConfig,
  ) {
  }

  // apiUri Формирование полной URI в соответствии с версией API из конфига
  protected createUri(suffix: string): string {
    return this.config.backend + this.config.endpoint + suffix;
  }

  protected async handleError(error: unknown, message: string, showSnack: boolean = true): Promise<never> {
    //message = await lastValueFrom(this.translation.get(message));
    try {
      if (showSnack) {
        // this.uiSnackBar.showMessage({
        //   // title: await lastValueFrom(this.translation.get('Текст для перевода.')),
        //   title: 'Внимание!!!',
        //   message,
        //   type: error.status && error.status >= 400 && error.status <= 499 ? 'warn' : 'error',
        // });
        console.log(message);
      }
    } catch (e: unknown) {
      console.warn('не обработанная ошибка: ', e);
    }
    return await lastValueFrom(throwError((): string => message));
  }

  /**
   * Получение количества записей.
   * @param urn         - Uri для получения.
   * @param options     - Параметры, фильтры, сортировка, пагинация.
   * @param accessToken - Сессионный токен доступа.
   * @return            - Количество записей с учётом переданной фильтрации.
   */
  protected apiCount(urn: string, options?: IGetListOption, accessToken?: string): Observable<number> {
    const headers: HttpHeaders = new HttpHeaders();
    let params: HttpParams = new HttpParams();

    if (accessToken) urn += `?accessToken=${accessToken}`;
    if (options) {
      if (options.limit) params = params.append('limit', `${options.limit.offset}:${options.limit.limit}`);
      if (options.by) {
        options.by.forEach((order: IGetListOptionOrder): void => {
          params = params.append('by', `${order.name}:${order.type}`);
        });
      }
      if (options.filter) {
        options.filter.forEach((filter: IGetListOptionFilter): void => {
          params = params.append('filter', `${filter.name}:${filter.type}:${filter.value}`);
        });
      }
    }
    return this.http
      .get<number>(urn, {
        observe: 'response', params,
        headers,
      })
      .pipe(
        catchError((error: any): Promise<never> => {
          switch (error.status) {
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.');
            default:
              return this.handleError(error, 'Сервер вернул неожиданную ошибку: ' + error.status.toString());
          }
        }),
        map((response: HttpResponse<number>): any => (response as any).body),
        debounceTime(200),
      );
  }

  /**
   * Получение идентификаторов (без кеширования запросов).
   * @param urn         - Uri для получения.
   * @param options     - Параметры, фильтры, сортировка, пагинация.
   * @param accessToken - Сессионный токен доступа.
   * @return            - Массив идентификаторов доступных для загрузки сущностей.
   */
  protected apiListId(urn: string, options?: IGetListOption, accessToken?: string): Observable<number[]> {
    const headers: HttpHeaders = new HttpHeaders();
    let params: HttpParams = new HttpParams();

    if (accessToken) urn += `?accessToken=${accessToken}`;
    if (options) {
      if (options.limit) params = params.append('limit', `${options.limit.offset}:${options.limit.limit}`);
      if (options.by) {
        options.by.forEach((order: IGetListOptionOrder): void => {
          params = params.append('by', `${order.name}:${order.type}`);
        });
      }
      if (options.filter) {
        options.filter.forEach((filter: IGetListOptionFilter): void => {
          params = params.append('filter', `${filter.name}:${filter.type}:${filter.value}`);
        });
      }
      if (options.tie)
        params = params.append('tie', `${options.tie}`);
    }
    return this.http
      .get<number[]>(urn, {
        observe: 'response', params,
        headers,
      })
      .pipe(
        catchError((error: any): Promise<never> => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Переданы не верные данные.',
                true
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.');
            default:
              return this.handleError(error, 'Сервер вернул неожиданную ошибку: ' + error.status.toString());
          }
        }),
        map((response: HttpResponse<number[]>): any => (response as any).body),
        debounceTime(200),
      );
  }

  /**
   * Получение подробной информации (без кеширования запросов).
   * @param uri         - Урл для получения.
   * @param ids         - Идентификаторы сущностей.
   * @param fnMap       - Функция обработки результата.
   * @param accessToken - Сессионный токен доступа.
   */
  protected apiListInfo<T>(
    uri: string,
    ids: number[],
    fnMap: (item: T) => T,
    accessToken?: string,
  ): Observable<T[]> {
    const headers: HttpHeaders = new HttpHeaders();

    if (accessToken) uri += `?accessToken=${accessToken}`;
    // Если запрашивается пустой список, сразу отдаём пустой ответ.
    if (ids.length === 0) {
      return new Observable<T[]>((data: Subscriber<T[]>): void => {
        data.next([]);
        data.complete();
      });
    }
    return this.http
      .get<T[]>(uri, {
        observe: 'response',
        headers,
      })
      .pipe(
        catchError((error: any): Promise<never> => {
          switch (error.status) {
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 404:
              return this.handleError(
                error,
                'Сущность отсутствует либо фильтрация исключила все результаты.',
                false
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.');
            default:
              return this.handleError(error, 'Сервер вернул неожиданную ошибку: ' + error.status.toString());
          }
        }),
        // Обработка данных функцией.
        map((response: HttpResponse<T[]>): any => (response as any).body.map(fnMap)),
        // Сортировка данных в порядке указанном в массиве идентификаторов.
        map((data: any): any[] => ids.map((id: number): any => data.find((item: any): boolean => item.id === +id)))
      );
  }

  /**
   * Создание новой сущности.
   * @param urn         - Урл для вызова метода API.
   * @param request     - Объект создаваемой сущности.
   * @param accessToken - Сессионный токен доступа.
   * @description - Стандартный метод API для создания сущностей на сервере.
   */
  protected apiCreate<T>(urn: string, request: T, accessToken?: string): Observable<number> {
    const headers: HttpHeaders = new HttpHeaders();

    if (accessToken) urn += `?accessToken=${accessToken}`;
    return this.http
      .post<IApiCreateResponse>(urn, request, {
        observe: 'response',
        headers,
      })
      .pipe(
        catchError((error: any): Promise<never> => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Передан не верный запрос.',
                true,
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        }),
        map((response: HttpResponse<IApiCreateResponse>): any => (response as any).body),
        map((data: any): number => data.id as number),
        debounceTime(200),
      );
  }

  /**
   * Изменение свойств существующей сущности.
   * @param urn         - Урл для вызова метода API.
   * @param request     - Объект изменяемой сущности.
   * @param accessToken - Сессионный токен доступа.
   * @description Метод изменяет часть свойств сущности.
   *              Метод доступен аутентифицированному пользователю состоящему в группе администратор.
   */
  protected apiUpdate<T>(urn: string, request: T, accessToken?: string): Observable<void> {
    const headers: HttpHeaders = new HttpHeaders();

    if (accessToken) urn += `?accessToken=${accessToken}`;
    return this.http
      .patch<void>(urn, request, {
        observe: 'response',
        headers,
      })
      .pipe(
        catchError((error: any): Promise<never> => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Передан не верный запрос.',
                true,
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 404:
              return this.handleError(
                error,
                'Изменяемая запись не существует или удалена.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        }),
        map((response: HttpResponse<void>): any => (response as any).body),
        debounceTime(200),
      );
  }

  /**
   * Удаление или пометка на удаление сущности на сервере.
   * @param urn         - Урл для вызова метода API.
   * @param accessToken - Сессионный токен доступа.
   */
  protected apiDelete<T>(urn: string, accessToken?: string): Observable<T> {
    const headers: HttpHeaders = new HttpHeaders();

    if (accessToken) urn += `?accessToken=${accessToken}`;
    return this.http
      .delete<T>(urn, {
        observe: 'response',
        headers,
      })
      .pipe(
        catchError((error: any): Promise<never> => {
          switch (error.status) {
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 404:
              return this.handleError(
                error,
                'Удаляемая запись отсутствует.',
                true
              );
            case 424:
              return this.handleError(
                error,
                'Запись удалять запрещено, существует запись привязанная к удаляемой записи.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.');
            default:
              return this.handleError(error, 'Сервер вернул неожиданную ошибку: ' + error.status.toString());
          }
        }),
        map((response: HttpResponse<T>): any => (response as any).body),
        debounceTime(200),
      );
  }
}
