import { NgModule } from '@angular/core';
import { CommonModule, NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { NgxMaskDirective } from "ngx-mask";

import { AppInputNumberComponent } from "./app-input-number.component";


@NgModule({
  declarations: [
    AppInputNumberComponent,
  ],
  imports: [
    CommonModule,
    NgxMaskDirective,
    NgClass,
    NgOptimizedImage,
    NgIf,
    FormsModule,
  ],
  exports: [
    AppInputNumberComponent,
  ]
})
export class AppInputNumberModule {
}
