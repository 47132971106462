import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ApiConfig } from "../api.types";
import { ApiService } from "../api.service";
import { ICityOfDepartureDeliveryCost } from "./calculator-rest-api.types";


const zeroTime: string = '0001-01-01T00:00:00Z';

@Injectable({
  providedIn: 'root',
})
export class CalculatorRestApiService extends ApiService {
  /**
   * Конструктор.
   * @param http - HTTP клиент.
   * @param config - Конфигурация API.
   */
  constructor(
    http: HttpClient,
    config: ApiConfig,
  ) {
    super(http, config);
  }

  /**
   * Список городов отправки груза из Китая для мини-приложения телеграм.
   * @param accessToken - Сессионный токен доступа.
   * @description       - Получение списка городов со стоимостью отправки груза из определённого города в Китае,
   *                      страна доставки груза Таджикистан.
   *                      Метод доступен аутентифицированному пользователю.
   */
  public CityOfDepartureDeliveryCost(accessToken?: string): Observable<ICityOfDepartureDeliveryCost[]> {
    const headers: HttpHeaders = new HttpHeaders();
    let urn: string = this.createUri('/delivery/calculation/app/city');
    if (accessToken) urn += `?accessToken=${accessToken}`;
    return this.http
      .get<ICityOfDepartureDeliveryCost[]>(urn, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response: HttpResponse<ICityOfDepartureDeliveryCost[]>) =>
          (response as any).body.map((rsp: ICityOfDepartureDeliveryCost): ICityOfDepartureDeliveryCost => {
            return Object.assign({}, rsp, {
              createAt: rsp.createAt && rsp.createAt !== zeroTime ? new Date(rsp.createAt) : undefined,
            }) as ICityOfDepartureDeliveryCost;
          })),
        catchError((error: any): Promise<never> => {
          switch (error.status) {
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.');
            default:
              return this.handleError(error, 'Сервер вернул неожиданную ошибку: ' + error.status.toString());
          }
        })
      );
  }
}
