import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, shareReplay } from "rxjs";

import { ICalculatorItem, ICalculatorRequest, ICalculatorResult } from "./calculator.types";
import { CalculatorRestApiService, ICityOfDepartureDeliveryCost, IRequestDataCalculationResponse } from "../api";
import { IRequestDataCalculationRequest, RequestDataRestApiService } from "../api";


/**
 * Сервис расчёта стоимости доставки.
 */
@Injectable({
  providedIn: 'root'
})
export class CalculatorService {
  private readonly costCity: Observable<ICityOfDepartureDeliveryCost[]>; // Обещание с буфером в 1 значение.
  private costCityOfDeparture: ICityOfDepartureDeliveryCost[]; // Список городов со стоимостями доставки.

  /** Конструктор. */
  constructor(
    private calculatorApiService: CalculatorRestApiService,
    private requestDataApiService: RequestDataRestApiService,
  ) {
    this.costCityOfDeparture = [];
    this.costCity = this.calculatorApiService.CityOfDepartureDeliveryCost()
      .pipe(shareReplay<ICityOfDepartureDeliveryCost[]>(1)); // Храним последнее значение в буфере.
    // Запрос справочника с сервера.
    this.costCity.subscribe((rsp: ICityOfDepartureDeliveryCost[]): void => {
      rsp.forEach((v: ICityOfDepartureDeliveryCost): number => this.costCityOfDeparture.push(v));
    });
  }

  /**
   * Выполнение вычисления стоимости доставки отправления в таджикистан, категория "посылки".
   * @param weight - Вес, килограмм.
   * @param width  - Ширина, метры.
   * @param height - Высота, метры.
   * @param length - Длинна, метры.
   * @description  - Условия расчёта:
   *   Стоимость за 1 кубический метр = $300 за килограмм, при плотности меньше 130 кг./метр кубический.
   *   Стоимость за 1 килограмм $2.5, применяется если плотность отправления больше 130 кг./метр кубический.
   */
  private calculateCostUsdForPackage(weight: number, width: number, height: number, length: number): number {
    const mm3Tom3: number = 1000000000.0; ////// Делитель для перевода миллиметра в кубе в метр в кубе.
    const densityThreshold: number = 130.0; //// Порог кг./метр кубический.
    const costByCubicMeter: number = 30000.0; // Стоимость доставки килограмма (в центах), за метр кубический.
    const costByOneKilogram: number = 250.0; /// Стоимость доставки килограмма (в центах), за килограмм.
    let mainVolume: number; //////////////////// Объём отправления в метрах в кубе.
    let density: number; /////////////////////// Плотность отправления (кг./метр).
    let costInCents: number = 0; /////////////// Стоимость отправления в центах.

    // Расчёт с защитой от деления на ноль.
    if (width * height * length <= 0) return costInCents;
    density = this.density(weight, width, height, length); // Плотность отправления.
    mainVolume = (width * height * length) / mm3Tom3;
    if (density < densityThreshold) costInCents = mainVolume * costByCubicMeter;
    else costInCents = (weight / 1000) * costByOneKilogram;

    // Возвращение результата расчёта.
    return costInCents;
  }

  /**
   * Выполнение вычисления стоимости доставки отправления в таджикистан, категория "груз".
   * @param fcId   - Код города отправки груза.
   * @param weight - Вес, килограмм.
   */
  private calculateCostUsdForCargo(fcId: number, weight: number): ICalculatorItem {
    let ret: ICalculatorItem; /////// Стоимость отправления.
    let fromCityCost: number = 0; /// Стоимость отправки груза за килограмм из выбранного города.
    let fromCityName: string = ''; // Название выбранного города отправления груза.

    this.costCityOfDeparture.forEach((item: ICityOfDepartureDeliveryCost): void => {
      if (fcId === item.id) [fromCityCost, fromCityName] = [item.costWeight, item.name];
    });
    if (fromCityName !== '') fromCityName = `Доставка из города ${fromCityName}`;
    else fromCityName = 'Доставка';
    ret = {
      cost: (weight / 1000) * fromCityCost,
      name: fromCityName,
    };

    // Возвращение результата расчёта.
    return ret;
  }

  /**
   * Расчёт плотности отправления (кг./м3.).
   * @param weight - Вес отправления в граммах.
   * @param width  - Ширина, миллиметров.
   * @param height - Высота, миллиметров.
   * @param length - Длинна, миллиметров.
   * @return - Плотность отправления в килограммах на метр в кубе.
   * @private
   */
  public density(weight: number, width: number, height: number, length: number): number {
    const mm3Tom3: number = 1000000000.0; // Делитель для перевода миллиметра в кубе в метр в кубе.
    const mainVolume: number = (width * height * length) / mm3Tom3;
    return (weight / 1000) / mainVolume;
  }

  /**
   * Загрузка данных о стоимости доставки грузов в таджикистан в зависимости от города отправления в китае.
   * @constructor
   */
  public CityDeliveryCost(): Promise<ICityOfDepartureDeliveryCost[]> {
    return new Promise<ICityOfDepartureDeliveryCost[]>((
      resolve: (value: (ICityOfDepartureDeliveryCost[] | PromiseLike<ICityOfDepartureDeliveryCost[]>)) => void,
      reject: (reason?: any) => void,
    ): void => {
      // Запрос справочника с сервера, вернётся последнее значение из буфера.
      lastValueFrom<ICityOfDepartureDeliveryCost[]>(this.costCity)
        .then((v: ICityOfDepartureDeliveryCost[]): void => resolve(v))
        .catch((e: unknown): void => reject(e))
    });
  }

  /**
   * Расчёт стоимости доставки.
   * @param req - Данные о доставляемом отправлении.
   */
  public Calculate(req: ICalculatorRequest): Promise<ICalculatorResult> {
    return new Promise<ICalculatorResult>((
      resolve: (value: (ICalculatorResult | PromiseLike<ICalculatorResult>)) => void,
      reject: (reason?: any) => void,
    ): void => {
      let ret: ICalculatorResult;
      let cost: number;
      let item: ICalculatorItem;

      switch (req.departure) {
        case 'package':
          cost = this.calculateCostUsdForPackage(req.weight, req.width, req.height, req.length);
          ret = {
            cost: 0, items: [
              {name: 'Доставка', cost: cost},
              {name: 'Упаковка', cost: 200},
            ]
          };
          break;
        case 'cargo':
          item = this.calculateCostUsdForCargo(req.fromCity, req.weight);
          ret = {
            cost: 0, items: [
              item,
              {name: 'Упаковка', cost: 200},
            ]
          };
          break;
        default:
          reject(`Не указана или указана не корректная категория отправления: "${req.departure}".`);
          return;
      }
      ret.cost = 0;
      ret.items.forEach((item: ICalculatorItem): void => {
        ret.cost += item.cost;
      });
      resolve(ret);
    });
  }

  /**
   * Отправка на сервер данных формы запроса на ручной просчёт стоимости доставки.
   * @param req - Данные о доставляемом отправлении.
   */
  public CalculateRequestByData(req: ICalculatorRequest): Promise<void> {
    return new Promise<void>((
      resolve: (value: (void | PromiseLike<void>)) => void,
      reject: (reason?: any) => void,
    ): void => {
      const data: IRequestDataCalculationRequest = {
        code: req.code,
        departure: req.departure,
        height: req.height,
        length: req.length,
        weight: req.weight,
        width: req.width,
        file: req.file,
      };
      lastValueFrom<IRequestDataCalculationResponse | null>(this.requestDataApiService.calculation(data))
        .then((_: IRequestDataCalculationResponse | null): void => resolve(void data))
        .catch((e: unknown): void => reject(e))
    });
  }
}
