import { NgModule } from '@angular/core';

import { ByteSizePipe } from "./byte-size.pipe";
import { LocalizedDatePipe } from './localized-date.pipe';
import { MinutesPipe } from "./minutes.pipe";
import { HoursPipe } from "./hours.pipe";
import { RublesPipe } from "./rub.pipe";
import { UsdPipe } from "./usd.pipe";


@NgModule({
  declarations: [
    ByteSizePipe,
    LocalizedDatePipe,
    MinutesPipe,
    HoursPipe,
    RublesPipe,
    UsdPipe,
  ],
  exports: [
    ByteSizePipe,
    LocalizedDatePipe,
    MinutesPipe,
    HoursPipe,
    RublesPipe,
    UsdPipe,
  ]
})
export class PipesModule {
}
