import { Injectable, OnDestroy, NgZone } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { Observable, ReplaySubject, EMPTY, lastValueFrom } from 'rxjs';
import { map, share, debounceTime } from 'rxjs/operators';

import { SessionRestApiService, ISessionFullInfoResponse, ISessionTimeInfoResponse } from '../api';
import { AuthServiceConfig, IAuthSession } from './auth.types';


@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private readonly authFullInfo$: ReplaySubject<ISessionFullInfoResponse>;
  private authSession?: IAuthSession = undefined;
  private isAuthenticatedSubject: ReplaySubject<boolean>;
  private isAdministratorSubject: ReplaySubject<boolean>;
  private authCheck$: Observable<boolean | undefined>;
  private NodeJSTimer: ReturnType<typeof setTimeout> | undefined;
  public isInitializationComplete: boolean = false;

  /**
   * Конструктор.
   * @param config - Конфиг сервиса
   * @param sessionApi - Клиент API аутентификации.
   * @param router - Сервис-роутер
   * @param ngZone - Библиотека ngZone
   */
  constructor(
    public config: AuthServiceConfig,
    private sessionApi: SessionRestApiService,
    private router: Router,
    private ngZone: NgZone
  ) {
    // Подписка ReplaySubject(1) ждет получения первого значения, затем получает значения из кеша.
    this.isAuthenticatedSubject = new ReplaySubject(1);
    this.isAdministratorSubject = new ReplaySubject(1);
    // Подписка на смену статуса аутентификации.
    this.isAuthenticatedSubject.subscribe((isAuthenticated: boolean): void => {
      this.handleAuthStateChange(isAuthenticated);
      if (!isAuthenticated) this.isAdministratorSubject.next(isAuthenticated);
    });
    // Буфер на одно последнее значение.
    this.authFullInfo$ = new ReplaySubject(1);
    // Единый запрос для избежания дублей запросов checkSession.
    this.authCheck$ = sessionApi.checkSession().pipe(
      debounceTime(200),
      share(),
    );
    // Немедленная проверка при старте обернута в таймаут для избежания зацикливания:
    // AuthService -> AuthInterceptor -> AuthService -> ..
    setTimeout(() => this.firstAuthCheck(), 0);
  }

  /** Деструктор. */
  ngOnDestroy(): void {
    this.clearTimeout();
  }

  protected clearTimeout(): void {
    if (this.NodeJSTimer) {
      clearTimeout(this.NodeJSTimer);
      this.NodeJSTimer = undefined;
    }
  }

  /**
   * Завершить процесс первичной инициализации.
   * @param isAuthenticated - Статус сессии.
   */
  protected async completeInitialization(isAuthenticated: boolean): Promise<void> {
    const completionObservable: Observable<void> = isAuthenticated ? this.createSession() : this.cleanSession();
    await lastValueFrom(completionObservable).finally((): void => {
      this.isInitializationComplete = true;
    });
  }

  /**
   * Проверка сессии аутентификации.
   * @private
   */
  private firstAuthCheck(): void {
    this.authCheck$.subscribe((isAuthenticated: boolean | undefined) => {
        if (isAuthenticated === undefined) isAuthenticated = false;
        this.completeInitialization(isAuthenticated!)
          .finally((): void => {
          })
      }
    );
  }

  /**
   * Observable с текущим статусом аутентификации пользователя.
   * @return - Observable с булевым значением.
   */
  public get isAuthenticated$(): Observable<boolean> {
    return this.isAuthenticatedSubject.asObservable();
  }

  /**
   * Observable с текущим статусом является ли пользователь администратором.
   * @return - Observable с булевым значением.
   */
  public get isAdministrator$(): Observable<boolean> {
    return this.isAdministratorSubject.asObservable();
  }

  /**
   * Создание сессии или загрузка информации о сесии (если используется httpOnly cookie).
   */
  public createSession(): Observable<void> {
    // Запросить через API краткую информацию о сессии.
    const retVal = this.sessionApi.getSession();
    retVal.subscribe({
      next: (session: ISessionTimeInfoResponse | undefined) => {
        // Обновить данные в this.authSession и изменить состояние на авторизованное.
        this.authSession = session;
        this.isAuthenticatedSubject.next(true);
        this.clearTimeout();
        this.NodeJSTimer = this.checkSession();
      },
      error: (_: any): void => {
        this.isAuthenticatedSubject.next(false);
      }
    });
    return retVal.pipe(map((_: ISessionTimeInfoResponse | undefined): undefined => void 0));
  }

  /**
   * Проверка сессии.
   */
  public checkSession(): ReturnType<typeof setTimeout> | undefined {
    if (this.authSession === null) {
      this.isAuthenticatedSubject.next(false);
      return undefined;
    }
    return setTimeout((): void => {
      let flag: boolean | undefined;

      this.ngZone.run(async (): Promise<void> => {
        try {
          flag = await lastValueFrom(this.authCheck$);
          if (flag) {
            this.clearTimeout();
            this.authSession = await lastValueFrom(this.sessionApi.getSession());
            this.NodeJSTimer = this.checkSession();
          } else {
            this.authSession = undefined;
            this.isAuthenticatedSubject.next(false);
          }
        } catch (e: unknown) {
          this.authSession = undefined;
          this.isAuthenticatedSubject.next(false);
        }
      })
        .then((_: void): void => {
        });
    }, (this.authSession!.lifetime + 1) * 1000);
  }

  /**
   * Удаление сессии авторизации.
   */
  public cleanSession(): Observable<void> {
    if (this.authSession === null) {
      this.isAuthenticatedSubject.next(false);
      return EMPTY;
    }
    lastValueFrom(this.sessionApi.deleteSession()).finally((): void => {
      this.authSession = undefined;
      this.isAuthenticatedSubject.next(false);
    })

    return EMPTY;
  }

  /**
   * Обработчик смены статуса сессии.
   * @param isAuthenticated Статус сессии.
   */
  public handleAuthStateChange(isAuthenticated: boolean): void {
    const nav: Navigation | null = this.router.getCurrentNavigation();
    let isRedirectUnauthorizedRequired: boolean = false;
    let isRedirectAuthorizedRequired: boolean = false;
    let currentUrn: string = '';

    if (!this.isInitializationComplete) {
      return;
    }
    if (nav && nav.finalUrl) currentUrn = nav.finalUrl.toString();
    // Проверка, является ли текущий URN одним из списка разрешённых для первого открытия
    // для не авторизованных пользователей.
    isRedirectUnauthorizedRequired = true;
    this.config.unauthorizedUrnList.forEach((urn: string): void => {
      if (currentUrn === urn) {
        isRedirectUnauthorizedRequired = false;
      }
    });
    // Проверка, является ли текущий URN одним из списка разрешённых для первого открытия
    // для авторизованных пользователей.
    isRedirectAuthorizedRequired = true;
    this.config.authorizedUrnList.forEach((urn: string): void => {
      if (currentUrn === urn) {
        isRedirectAuthorizedRequired = false;
      }
    });
    // Редирект, пользователь не авторизован и находится на URN не в списке.
    if (!isAuthenticated && isRedirectUnauthorizedRequired) {
      this.unauthorizedRedirect().then((_: void): void => {
      });
    }
    // Редирект, пользователь авторизован и находится на URN не в списке.
    // Но помимо этого правила, в настройках роутинга есть флаги, редирект будет сделан так же относительно флага.
    if (isAuthenticated && isRedirectAuthorizedRequired) {
      this.authorizedRedirect().then((_: void): void => {
      });
    }
  }

  /**
   * Редирект не авторизованного пользователя.
   * @param urn - URN для перенаправления.
   */
  protected async unauthorizedRedirect(urn?: string): Promise<void> {
    if (void 0 === urn) {
      urn = this.config.defaultUnauthorizedUrn;
    }
    await this.router.navigateByUrl(urn);
    this.clearTimeout();
  }

  /**
   * Редирект авторизованного пользователя только если он находится на страницах для не авторизованных пользователей.
   * @param urn - URN для перенаправления.
   */
  protected async authorizedRedirect(urn?: string): Promise<void> {
    const flag: boolean = this.config.unauthorizedUrnList.some((url: string): boolean =>
      this.router.url.startsWith(url)
    );
    if (flag) {
      if (void 0 === urn) {
        urn = this.config.defaultAuthorizedUrn;
      }
      await this.router.navigateByUrl(urn);
    }
  }

  /**
   * Получение полной информации о сессии аутентификации.
   * @return Объект - ISessionFullInfoResponse.
   */
  public get authSessionFullInfo$(): Observable<ISessionFullInfoResponse> {
    return this.authFullInfo$.pipe(
      debounceTime(200),
      share(),
    );
  }

  /** Функция выполняет проверку сессии аутентификации на сервере и обновляет данные сервиса. */
  public async checkSessionFullInfoRetry(): Promise<void> {
    let sessionFullInfo: ISessionFullInfoResponse;
    let session: IAuthSession;
    try {
      // Запрос полной информации о сесии.
      sessionFullInfo = await lastValueFrom(this.sessionApi.getSessionFullInfo());
      // Создание объекта короткой информации.
      session = {
        createAt: sessionFullInfo.createAt,
        expiresAt: sessionFullInfo.expiresAt,
        lifetime: sessionFullInfo.lifetime,
      }
      // Рассылка всем подписчикам.
      this.authFullInfo$.next(sessionFullInfo);
      this.isAuthenticatedSubject.next(true);
      this.isAdministratorSubject.next(sessionFullInfo.isAdministrator);
      this.authSession = session;
      this.clearTimeout();
      this.NodeJSTimer = this.checkSession();
    } catch (e: unknown) {
      this.authSession = undefined;
      this.isAuthenticatedSubject.next(false);
      this.isAdministratorSubject.next(false);
    }
  }
}
