import { Pipe, PipeTransform } from '@angular/core';


/** Интерфейс элементов справочника. */
export interface IByteSize {
  /** Название единицы (ГОСТ 8.417—2002). */
  name: string;
  /** Символ. */
  symbol: string;
  /** Приставка СИ (международная система единиц). */
  si: string;
  /** Название по МЭК (международная электротехническая комиссия). */
  iec: string;
  /** Обозначение по МЭК (международная электротехническая комиссия). */
  sIec: string;
  /** Обозначение по МЭК (международная электротехническая комиссия) на эльфийском. */
  sIecEng: string;
  /** Степень числа 2. */
  degree: number;
}

/** Справочник единиц. */
export const ByteSizeName: IByteSize[] = [
  {name: 'байт', symbol: 'Б', si: '', iec: 'байт', sIec: 'Б', sIecEng: 'B', degree: 0},
  {name: 'килобайт', symbol: 'Кбайт', si: 'кило-', iec: 'кибибайт', sIec: 'КиБ', sIecEng: 'KiB', degree: 10},
  {name: 'мегабайт', symbol: 'Мбайт', si: 'мега-', iec: 'мебибайт', sIec: 'МиБ', sIecEng: 'MiB', degree: 20},
  {name: 'гигабайт', symbol: 'Гбайт', si: 'гига-', iec: 'гибибайт', sIec: 'ГиБ', sIecEng: 'GiB', degree: 30},
  {name: 'терабайт', symbol: 'Тбайт', si: 'тера-', iec: 'тебибайт', sIec: 'ТиБ', sIecEng: 'TiB', degree: 40},
  {name: 'петабайт', symbol: 'Пбайт', si: 'пета-', iec: 'пебибайт', sIec: 'ПиБ', sIecEng: 'PiB', degree: 50},
  {name: 'эксабайт', symbol: 'Эбайт', si: 'экса-', iec: 'эксбибайт', sIec: 'ЭиБ', sIecEng: 'EiB', degree: 60},
  {name: 'зеттабайт', symbol: 'Збайт', si: 'зетта-', iec: 'зебибайт', sIec: 'ЗиБ', sIecEng: 'ZiB', degree: 70},
  {name: 'йоттабайт', symbol: 'Ибайт', si: 'йотта-', iec: 'йобибайт', sIec: 'ЙиБ', sIecEng: 'YiB', degree: 80},
  {name: 'роннабайт', symbol: 'Рбайт', si: 'ронна-', iec: 'ронибайт', sIec: 'РиБ', sIecEng: 'RiB', degree: 90},
  {name: 'кветтабайт', symbol: 'Вбайт', si: 'кветта-', iec: 'кветибайт', sIec: 'ВиБ', sIecEng: 'ViB', degree: 100},
];

@Pipe({
  name: 'bytesize'
})
export class ByteSizePipe implements PipeTransform {
  transform(value: number, decimals = 2): string {
    const isToMuch: string = 'Много';
    let ret: string = '-';

    if (!+value) return ret;
    const k: number = 1024;
    const dm: number = decimals < 0 ? 0 : decimals;
    const i: number = Math.floor(Math.log(value) / Math.log(k));
    if (i >= ByteSizeName.length) ret = isToMuch;
    else ret = `${parseFloat((value / Math.pow(k, i)).toFixed(dm))} ${ByteSizeName[i].sIec}`;

    return ret;
  }
}
