<fieldset>
  <input
    type="text"
    [(ngModel)]="valueNumber" (ngModelChange)="onChange($event)"
    mask="separator.3" thousandSeparator=" " suffix="{{ suffix }}" prefix="{{ prefix }}"
    [validation]="true"
    [placeholder]="placeholder"
    [ngClass]="{
      'border-[#FF0000]': !isReadonly && isInvalid && isTouched,
      'select-none hover:bg-[#1C1C1D] hover:bg-opacity-[8%]': isReadonly,
      'select-all': !isReadonly}"
    [readonly]="isReadonly"
    class="input_text"/>
  <div class="relative" *ngIf="!isReadonly && isTouched">
    <img
      (click)="valueReset()"
      ngSrc="assets/images/cross.svg" alt="Стереть" height="14" width="14"
      class="absolute right-[10px] -top-[27px] z-2 cursor-pointer">
  </div>
  <div *ngIf="!isReadonly && isInvalid && isTouched" class="text-[#FF0000] flex flex-col">
    <div
      *ngFor="let e of help"
    >{{ e }}
    </div>
  </div>
</fieldset>
