import { Subject } from "rxjs";

import { TelegramEventData } from "./telegram.types";


/**
 * Базовый класс работы с обещаниями.
 */
export class TelegramPromise {
  protected readonly event$: Subject<TelegramEventData>;

  /** Конструктор. */
  constructor() {
    this.event$ = new Subject<TelegramEventData>();
  }

  /** Подписка на события обратного вызова. */
  public Event(): Subject<TelegramEventData> {
    return this.event$;
  }

  /**
   * Создание обещания для функций обратного вызова без результата.
   * @param id - Идентификатор события обратного вызова.
   */
  public makePromiseVoid(id: string): Promise<void> {
    return new Promise<void>((
      resolve: (value: (PromiseLike<void> | void)) => void,
      _reject: (reason?: any) => void) => {
      this.Event().subscribe((e: TelegramEventData) => {
        if (e.id !== id) return;
        resolve();
      });
    });
  }

  /**
   * Создание обещания для функций обратного вызова без результата, но в случае возврата ошибки, создающее исключение.
   * @param id - Идентификатор события обратного вызова.
   */
  public makePromiseVoidWithException(id: string): Promise<void> {
    return new Promise<void>((
      resolve: (value: (PromiseLike<void> | void)) => void,
      reject: (reason?: any) => void) => {
      this.Event().subscribe((e: TelegramEventData) => {
        let err: string = '';
        let ok: boolean = false;
        if (e.id !== id) return;
        if (e.args && e.args.length > 1) [err, ok] = [e.args[0] as string, e.args[1] as boolean];
        if (!ok || err) reject(err);
        resolve();
      });
    });
  }

  /**
   * Создание обещания для функций обратного вызова с одним результатом строкового типа.
   * @param id - Идентификатор события обратного вызова.
   */
  public makePromiseString(id: string): Promise<string> {
    return new Promise<string>((
      resolve: (value: (PromiseLike<string> | string)) => void,
      _reject: (reason?: any) => void) => {
      this.Event().subscribe((e: TelegramEventData) => {
        let ret: string = '';
        if (e.id !== id) return;
        if (e.args && e.args.length > 0) ret = e.args[0] as string;
        resolve(ret);
      });
    });
  }

  /**
   * Создание обещания для функций обратного вызова с одним результатом строкового типа, в случае возврата ошибки,
   * создающее исключение.
   * @param id - Идентификатор события обратного вызова.
   */
  public makePromiseStringWithException(id: string): Promise<string> {
    return new Promise<string>((
      resolve: (value: (PromiseLike<string> | string)) => void,
      reject: (reason?: any) => void) => {
      this.Event().subscribe((e: TelegramEventData) => {
        let ret: string = '';
        let err: string = '';
        if (e.id !== id) return;
        if (e.args && e.args.length > 1) [err, ret] = [e.args[0] as string, e.args[1] as string];
        if (err) reject(err);
        resolve(ret);
      });
    });
  }

  /**
   * Создание обещания для получения строкового множества, в случае возврата ошибки, создающее исключение.
   * @param id - Идентификатор события обратного вызова.
   */
  public makePromiseStringArrayWithException(id: string): Promise<string[]> {
    return new Promise<string[]>((
      resolve: (value: (PromiseLike<string[]> | string[])) => void,
      reject: (reason?: any) => void) => {
      this.Event().subscribe((e: TelegramEventData) => {
        let ret: string[] = [];
        let err: string = '';
        if (e.id !== id) return;
        if (e.args && e.args.length > 1) [err, ret] = [e.args[0] as string, Object.values<string>(e.args[1] as any)];
        if (err) reject(err);
        resolve(ret);
      });
    });
  }

  /**
   * Создание обещания для функций обратного вызова с одним аргументом булево типа.
   * @param id - Идентификатор события обратного вызова.
   */
  public makePromiseBoolean(id: string): Promise<boolean> {
    return new Promise<boolean>((
      resolve: (value: (PromiseLike<boolean> | boolean)) => void,
      _reject: (reason?: any) => void) => {
      this.Event().subscribe((e: TelegramEventData) => {
        let ret: boolean = false;
        if (e.id !== id) return;
        if (e.args && e.args.length > 0) ret = e.args[0] as boolean;
        resolve(ret);
      });
    });
  }

  /**
   * Создание обещания для функций обратного вызова с двумя аргументами.
   * @param id - Идентификатор события обратного вызова.
   */
  public makePromiseNilString(id: string): Promise<string | null> {
    return new Promise<string | null>((
      resolve: (value: (PromiseLike<string | null> | string | null)) => void,
      _reject: (reason?: any) => void) => {
      this.Event().subscribe((e: TelegramEventData) => {
        let ret: string | null = null;
        if (e.id !== id) return;
        if (e.args && e.args.length > 0) if (e.args[0] as boolean) ret = e.args[1] as string;
        resolve(ret);
      });
    });
  }
}
