import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ApiConfig } from "../api.types";
import { ApiService } from "../api.service";
import { IRequestDataCalculationRequest, IRequestDataCalculationResponse } from "./request-data-rest-api.types";
import { IRequestDataSearchRequest, IRequestDataSearchResponse } from "./request-data-rest-api.types";


@Injectable({
  providedIn: 'root',
})
export class RequestDataRestApiService extends ApiService {
  /**
   * Конструктор.
   * @param http - HTTP клиент.
   * @param config - Конфигурация API.
   */
  constructor(
    http: HttpClient,
    config: ApiConfig,
  ) {
    super(http, config);
  }

  /**
   * Сохранение запроса расчёта стоимости, отправки груза из Китая.
   * @param req - Данные запроса.
   */
  public calculation(req: IRequestDataCalculationRequest): Observable<IRequestDataCalculationResponse | null> {
    const urn: string = this.createUri('/request/calculation');
    const headers: HttpHeaders = new HttpHeaders();
    return this.http
      .post<IRequestDataCalculationResponse | null>(urn, req, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response: HttpResponse<IRequestDataCalculationResponse | null>): IRequestDataCalculationResponse | null =>
          response.body), catchError((error: any): Promise<never> => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Передан не верный запрос.',
                false,
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        })
      );
  }

  /**
   * Сохранение запроса поиска товаров в Китае.
   * @param req - Данные запроса.
   */
  public search(req: IRequestDataSearchRequest): Observable<IRequestDataSearchResponse | null> {
    const urn: string = this.createUri('/request/search');
    const headers: HttpHeaders = new HttpHeaders();
    return this.http
      .post<IRequestDataSearchResponse | null>(urn, req, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response: HttpResponse<IRequestDataSearchResponse | null>): IRequestDataSearchResponse | null =>
          response.body), catchError((error: any): Promise<never> => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Передан не верный запрос.',
                false,
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        })
      );
  }
}
