import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'input-small-number',
  templateUrl: './input-small-number.component.html',
  styleUrls: ['./input-small-number.component.scss']
})
export class InputSmallNumberComponent {
  @Input() num: number = 0;
  @Input() min: number = 0;
  @Input() max: number = 0;
  @Output() public response:EventEmitter<number> = new EventEmitter<number>();

  constructor() {
  }

  public onEnter(): void {
    if (this.num >= this.min && this.num <= this.max) {
      this.response.next(this.num);
    }
  }
}
