import { Pipe, PipeTransform } from '@angular/core';

import { NgxMaskPipe } from "ngx-mask";


@Pipe({
  name: 'usd'
})
export class UsdPipe implements PipeTransform {
  constructor(
    private mask: NgxMaskPipe,
  ) {
  }

  transform(value: number): string {
    const usd: number = value / 100;
    let ret: string = '-';

    if (value === 0) ret = 'Бесплатно';
    else {
      ret = usd.toFixed(2);
      ret = this.mask.transform(ret, 'separator.2', {thousandSeparator: ' ', prefix: '$'});
      ret = ret.replace(/\./, ",");
    }

    return ret;
  }
}
