import { ComponentRef, Directive, ElementRef, EventEmitter, inject, Renderer2 } from '@angular/core';
import { AfterViewInit, ViewContainerRef, Input, Output, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject, Subscription } from "rxjs";

import { SpriteIconComponent } from "../../_components";
import { IColumnWidth } from "./table-resize.types";


/**
 * Директива таблицы позволяющая изменять размер колонок таблицы.
 * @param disabled    - Отключён режим изменения размера колонок.
 * @param busy        - Состояние "занято", для отключения ручек.
 * @param columnWidth - Начальные настройки ширины колонок таблицы.
 *
 * Пример использования:
 * @example
 * <table tableResize [disabled]="disabled">
 *   <thead><tr><th></th><th></th></tr></thead>
 *   <tbody><tr><td></td><td></td></tr></tbody>
 * </table>
 */
@Directive({
  selector: '[tableResize]'
})
export class TableResizeDirective implements AfterViewInit, OnInit, OnDestroy {
  private busySubscribe?: Subscription = undefined; // Подписка на канал получения статуса "занято".
  private busyState: boolean = true; // Текущее состояние "занято".
  private thCollection!: HTMLCollectionOf<HTMLTableCellElement>;
  private th!: HTMLTableCellElement;
  private nextTh!: HTMLTableCellElement;
  private index: number | undefined;
  private width!: number;
  private cursorStartPosition: number = 0;
  private nextWidth: number | undefined;
  private listeners: Function[] = [];
  private dragStart: boolean = false;
  private componentInstance: ComponentRef<SpriteIconComponent>[] = [];

  @Input() columnWidth: Map<number, IColumnWidth> | undefined = undefined;
  @Input('busy') busy$!: ReplaySubject<boolean>;
  @Input() disabled: boolean = false;
  @Output() readonly outColumnWidthResponse: EventEmitter<Map<number, IColumnWidth>> = new EventEmitter<Map<number, IColumnWidth>>();

  table!: HTMLTableElement;

  /**
   * Конструктор.
   *
   * @param element - Обёртка над нативными HTML элементами.
   * @param renderer - Обработчик визуализации.
   * @param viewContainerRef
   */
  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
  ) {
  }

  /** Инициализатор. */
  ngOnInit(): void {
    try {
      this.busySubscribe = this.busy$.subscribe((v: boolean): void => {
        this.busyState = v;
      });
    } catch (e: unknown) {
      // console.error('В компонент TableResizeDirective не передан канал "занято".')
    }
  }

  /** Деструктор. */
  ngOnDestroy(): void {
    if (this.busySubscribe) {
      this.busySubscribe.unsubscribe();
      this.busySubscribe = undefined;
    }
    this.componentInstance.forEach((item: ComponentRef<SpriteIconComponent>): void => {
      item.destroy();
    });
  }

  /** Событие завершения отображения элементов HTML страницы. */
  ngAfterViewInit(): void {
    if (this.disabled) return;
    this.table = this.element.nativeElement;
    this.create();
  }

  /**
   * Динамическое создание экземпляра другого компонента angular.
   *
   * @param name - Название иконки содержащейся в спрайте.
   * @param width - Ширина иконки.
   * @param height - Высота иконки.
   */
  private createIcon(name: string, width: number, height: number): ComponentRef<SpriteIconComponent> | undefined {
    let item: ComponentRef<SpriteIconComponent> | undefined = undefined;

    // TODO: Код вызывает ошибку браузера: ERROR TypeError: type is undefined
    // try {
    //  item = this.viewContainerRef.createComponent(SpriteIconComponent);
    // } catch (e: unknown) {
    //   console.error(`создание html объекта SpriteIconComponent прервано ошибкой: ${e}`);
    // }
    // if (item !== undefined) {
    //   item.setInput('name', name);
    //   item.setInput('width', width);
    //   item.setInput('height', height);
    //   item.changeDetectorRef.detectChanges();
    // }

    return item;
  }

  /** Создание элемента изменяющего размер колонки таблицы. */
  private create(): void {
    let columnWidth: IColumnWidth | undefined = undefined;
    let width: string | number;
    let item: ComponentRef<SpriteIconComponent> | undefined;

    // Формирование необходимых "ручек" управления.
    this.thCollection = this.table.getElementsByTagName('th');
    for (let n: number = 0; n < this.thCollection.length; n++) {
      if (this.columnWidth && this.columnWidth.has(n)) columnWidth = this.columnWidth.get(n);
      if (n !== this.thCollection.length) {
        // Внизу иконка.
        if (n !== this.thCollection.length - 1) { // Если не последняя колонка.
          if (!columnWidth || (columnWidth && !columnWidth.isNoResize)) { // Если не запрещено изменять размер.
            item = this.createIcon('column-slider-16px-active', 16, 16);
            if (item !== undefined) {
              this.componentInstance.push(item);
              const icon: any = item.location.nativeElement;
              this.busyState // tailwindcss
                ? icon.className = 'h-[16px] w-[16px] -mt-[8px] absolute -right-[8.5px] top-[50%] z-10'
                : icon.className = 'h-[16px] w-[16px] -mt-[8px] absolute cursor-col-resize -right-[8.5px] top-[50%] z-10';
              this.renderer.appendChild(this.thCollection[n], icon);
            }
          }
        }
        // Поверх иконки прозрачный слой с "ручкой".
        const resizeHandle: HTMLDivElement = document.createElement('div');
        if (n !== this.thCollection.length - 1) { // Если не последняя колонка.
          if (!columnWidth || (columnWidth && !columnWidth.isNoResize)) { // Если не запрещено изменять размер.
            this.busyState // tailwindcss
              ? resizeHandle.className = 'h-[16px] w-[16px] -mt-[8px] absolute -right-[8.5px] top-[50%] z-20'
              : resizeHandle.className = 'h-[16px] w-[16px] -mt-[8px] absolute cursor-col-resize -right-[8.5px] ' +
                'top-[50%] z-20';
            this.renderer.addClass(resizeHandle, 'resize-handle');
            this.renderer.appendChild(this.thCollection[n], resizeHandle);
          }
        }
        // Прикручивание ручки.
        if (n !== this.thCollection.length - 1) { // Если не последняя колонка.
          this.renderer.listen(resizeHandle, 'mousedown', this.mouseDown);
          this.renderer.setAttribute(resizeHandle, 'th-resize', `${n}`);
        }
        this.renderer.setStyle(this.thCollection[n], 'position', 'relative');
      }
      width = Math.round(this.thCollection[n].getBoundingClientRect().width);
      if (columnWidth && !columnWidth.isNoResize) {
        const newValue: number | undefined = columnWidth.width;
        width = (newValue === undefined || newValue === 0) ? width : newValue;
        columnWidth.width = width;
      }
      this.setWidth(this.thCollection[n], width);
      if (this.columnWidth && columnWidth) this.columnWidth.set(n, columnWidth);
    }
  }

  /** Установка значения ширины колонки в пикселях. */
  private setWidth(element: HTMLTableCellElement, width: number | string): void {
    this.renderer.setStyle(element, 'width', width + (width === 'auto' ? '' : 'px'));
  }

  /** Изменение размера колонки таблицы. */
  private resize(newNextWidth: number, newWidth: number): void {
    this.setWidth(this.th, newWidth);
    if (this.nextTh != undefined) {
      this.setWidth(this.nextTh, newNextWidth);
    }
  }

  /** Событие окончания перемещения мыши. */
  endDrag: () => void = (): void => {
    if (this.busyState) return;
    let width: number = 0;
    this.renderer.removeStyle(this.table.firstElementChild, 'cursor');
    if (this.dragStart) {
      this.dragStart = false;
    }
    this.listeners.forEach((fn: Function): any => fn());
    this.listeners = [];
    // Обновление текущих значений колонок.
    for (let n: number = 0; n < this.thCollection.length; n++) {
      width = Math.round(this.thCollection[n].getBoundingClientRect().width);
      if (this.columnWidth && this.columnWidth.has(n)) {
        const columnWidth: IColumnWidth | undefined = this.columnWidth.get(n);
        if (columnWidth && !columnWidth.isNoResize) {
          columnWidth.width = width;
          this.columnWidth.set(n, columnWidth);
        }
      }
    }
    // Передача размера колонок в канал.
    if (this.columnWidth) {
      this.outColumnWidthResponse.next(this.columnWidth);
    }
  }

  /** Событие нажатия кнопки мыши. */
  mouseDown: (event: MouseEvent) => void = (event: MouseEvent): void => {
    if (this.busyState) return;
    if (this.table.firstElementChild === null) return;
    const theadRow: Element = this.table.firstElementChild;
    this.renderer.setStyle(theadRow, 'cursor', 'col-resize');
    const listenerMove: () => void = this.renderer.listen(theadRow, 'mousemove', this.mouseMove);
    const listenerLeave: () => void = this.renderer.listen(theadRow, 'mouseleave', this.endDrag);
    const listenerUp: () => void = this.renderer.listen(theadRow, 'mouseup', this.endDrag);
    this.listeners.push(listenerMove, listenerLeave, listenerUp);
    const resize: EventTarget | null = event.target;
    this.index = parseInt(`${(resize as HTMLDivElement).getAttribute('th-resize')}`);
    this.th = this.thCollection[this.index];
    this.nextTh = this.thCollection[this.index + 1];
    this.dragStart = true;
    this.cursorStartPosition = event.pageX;
    if (typeof this.th === 'object') {
      this.width = parseInt(getComputedStyle(this.th, undefined).getPropertyValue('width'), 10);
    }
    if (typeof this.nextTh === 'object' && this.nextTh != undefined) {
      this.nextWidth = parseInt(getComputedStyle(this.nextTh, undefined).getPropertyValue('width'), 10);
    }
  }

  /** Событие перемещения мыши. */
  mouseMove: (event: MouseEvent) => void = (event: MouseEvent): void => {
    let newNextWidth: number = 0;
    if (this.busyState) return;
    if (this.dragStart) {
      const cursorPosition: number = event['pageX'];
      const mouseMoved: number = (cursorPosition - this.cursorStartPosition);
      const newWidth: number = this.width + mouseMoved;

      if (this.nextWidth) newNextWidth = this.nextWidth;
      if (this.nextTh !== undefined && this.nextWidth !== undefined) {
        newNextWidth = this.nextWidth - mouseMoved;
      }
      if (newWidth > 50 && (newNextWidth > 50 || this.nextTh == undefined)) {
        this.resize(newNextWidth, newWidth);
      }
    }
  }
}
