import { EventManager } from "@angular/platform-browser";

import { TelegramPromise } from "./telegram.promise";
import { IButtonType, ICallbackEvents, TelegramEventData } from "./telegram.types";
import { Subject } from "rxjs";


const eventKey: string = 'telegram.service.webapp.event';

/**
 * Базовый класс вспомогательных функций.
 */
export class TelegramEvents extends TelegramPromise {
  protected readonly callbackEvents$: ICallbackEvents[];

  /** Конструктор. */
  constructor(
    private eventManager: EventManager,
  ) {
    super();
    this.callbackEvents$ = [];
    this.eventManager.addEventListener(
      window as unknown as HTMLElement, // В таком виде TS-Lint не впадает в истерику.
      eventKey,
      this.onWindowDispatchEvent.bind(this),
    );
  }

  /**
   * Функция получения именованного JS события из внешней функции написанной на javascript.
   * Событие помимо стандартных данных содержит объект описания пользователя, полученный через обратный вызов,
   * от сервера telegram.
   * Данная функция извлекает данные пользователя из объекта события, формирует новую структуру данных для
   * отправки на сервер и отправляет на сервер.
   * @param event - Объект события javascript.
   */
  private onWindowDispatchEvent(event: Event): void {
    let key: string;
    let ted: TelegramEventData;
    let fnd: boolean;

    try {
      ted = (event as any).detail as TelegramEventData;
      if (ted === undefined) return;
      [key, fnd] = [ted.id, false];
      this.callbackEvents$.forEach((evt: ICallbackEvents) => {
        if (evt.key === key) {
          fnd = true;
          evt.sub.next({} as never);
        }
      });
      if (!fnd) this.event$.next(ted);
    } catch (e: unknown) {
      // console.error('В сервис TelegramService пришло не известное событие.');
    }
  }

  /** Создание объекта событий и подписки кнопки по типу кнопки. */
  protected makeICallbackEvent(t: IButtonType): ICallbackEvents {
    let ret: ICallbackEvents;
    let key: string;

    key = this.makeRandomString();
    ret = {
      type: t,
      key: key,
      fn: this.makeCallback(key),
      sub: new Subject<never>(),
    }

    return ret;
  }

  /** Генератор уникального строкового значения. */
  public makeRandomString(len: number = 32): string {
    const dic: string = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let ret: string = '';

    for (let i = 0; i < len; i++) {
      ret += dic.charAt(Math.floor(Math.random() * dic.length));
    }

    return ret;
  }

  /**
   * Создание функции обратного вызова, которая примет событие и передаст его через windows.dispatchEvent() событие.
   * Функция makeOnClickTelegramDispatchEvent() описана в javascript файле.
   * @param id - Идентификатор события.
   */
  public makeCallback(id: string): Function {
    let ret: Function;
    // @ts-ignore
    ret = makeOnClickTelegramDispatchEvent(id, eventKey);
    return ret;
  }
}
