import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ApiConfig, IGetListOption } from "../api.types";
import { ApiService } from "../api.service";
import { ITUser, ITUserCreateRequest, ITUserCreateResponse, ITUserUpdateRequest } from "./user-rest-api.types";


@Injectable({
  providedIn: 'root',
})
export class UserRestApiService extends ApiService {
  /**
   * Конструктор.
   * @param http - HTTP клиент.
   * @param config - Конфигурация API.
   */
  constructor(
    http: HttpClient,
    config: ApiConfig,
  ) {
    super(http, config);
  }

  /**
   * Список идентификаторов пользователей.
   * @param options - Опции лимитов, сортировки, фильтрации.
   * @param accessToken - Токен доступа при аутентификации с доступом к токену.
   * @description - Метод возвращает список идентификаторов пользователей системы.
   *                Метод доступен аутентифицированному пользователю со статусом администратор.
   */
  public listId(options?: IGetListOption, accessToken?: string): Observable<number[]> {
    const urn: string = this.createUri(`/tuser`);
    return this.apiListId(urn, options, undefined);
  }

  /**
   * Подробная информация о пользователе.
   * @param ids - Идентификаторы через запятую.
   * @param accessToken - Токен доступа при аутентификации с доступом к токену.
   * @description - Получение подробной информации о пользователе.
   *                В качестве идентификатора можно передавать один или несколько идентификаторов, разделённых запятой.
   *                Метод доступен аутентифицированному пользователю со статусом администратор.
   */
  public listInfo(ids: number[], accessToken?: string): Observable<ITUser[]> {
    const urn: string = this.createUri(`/tuser/${ids.join(',')}`);
    return this.apiListInfo<ITUser>(
      urn,
      ids,
      (item: ITUser) => {
        return Object.assign({}, item, {
          createAt: item.createAt ? new Date(item.createAt) : undefined,
          telegramAuthDateAt: item.telegramAuthDateAt ? new Date(item.telegramAuthDateAt) : undefined,
        });
      },
      accessToken,
    );
  }

  /**
   * Создание пользователя.
   * @param request - Данные формы для создания пользователя.
   * @description - Метод создаёт запись о пользователе.
   *                Метод доступен аутентифицированному пользователю со статусом администратор.
   */
  public create(request: ITUserCreateRequest): Observable<ITUserCreateResponse | null> {
    const urn: string = this.createUri(`/tuser`);
    const headers: HttpHeaders = new HttpHeaders();
    return this.http
      .post<ITUserCreateResponse>(urn, request, {
        observe: 'response',
        headers,
      })
      .pipe(
        map(response => response.body),
        catchError((error) => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Передан не верный запрос.',
                false,
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 409:
              return this.handleError(
                error,
                'Уже существует пользователь с указанным ID телеграм пользователя.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        })
      );
  }

  /**
   * Изменение свойств пользователя.
   * @param tuserId - Уникальный идентификатор пользователя.
   * @param request - Данные формы редактирования пользователя.
   * @description - Метод изменяет часть свойства записи о пользователе.
   *                Метод доступен аутентифицированному пользователю со статусом администратор.
   */
  public update(tuserId: number, request: ITUserUpdateRequest): Observable<void | null> {
    const urn: string = this.createUri(`/tuser/${tuserId}`);
    const headers: HttpHeaders = new HttpHeaders();
    return this.http
      .patch<void>(urn, request, {
        observe: 'response',
        headers,
      })
      .pipe(
        map(response => response.body),
        catchError((error) => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Передан не верный запрос.',
                false,
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        })
      );
  }

  /**
   * Удаление пользователя.
   * @param tuserId - Уникальный идентификатор пользователя.
   * @param accessToken - Токен доступа при аутентификации с доступом к токену.
   * @description Метод помечает партнёра на удаление. Физическое удаление записи произойдёт отложено,
   *              через несколько суток.
   *              Метод доступен аутентифицированному пользователю состоящему в группе администратор.
   */
  public delete(tuserId: number, accessToken?: string): Observable<void | null> {
    const urn: string = this.createUri(`/tuser/${tuserId}`);
    return this.apiDelete<void>(urn, accessToken);
  }
}
