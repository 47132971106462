import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { map, Observable } from "rxjs";
import { catchError, debounceTime } from "rxjs/operators";

import { ApiConfig } from '../api.types';
import { ApiService } from '../api.service';

import { IUploadFileResponse } from "./file-api.types";


@Injectable({
  providedIn: 'root'
})
export class FileRestApiService extends ApiService {
  /**
   * Конструктор.
   * @param http - HTTP клиент.
   * @param config - Конфигурация API.
   */
  constructor(
    http: HttpClient,
    config: ApiConfig,
  ) {
    super(http, config);
  }

  /**
   * Возвращается URN для загрузки файла по ID файла.
   * @param id   - Уникальный идентификатор файла.
   * @param mode - Режим загрузки файла.
   */
  public fileUrnById(id: number, mode: string = 'inline'): string {
    return this.createUri(`/file/${id}?mode=${mode}`);
  }

  /**
   * Сохранение файла на сервере.
   * @param file - Объект сохраняемого на сервере файла.
   * @description Запрос выполняет загрузку файла или файлов во временную папку на сервере.
   *              Переданным файлам присваивается уникальный идентификатор и выставляется срок жизни,
   *              после которого файлы удаляются. Все компоненты сервера которым передаётся ID файла,
   *              делают копию файла в другую локацию для использования и постоянного хранения.
   *              Срок жизни файлов определяется настройками сервера и может быть от нескольких минут
   *              до нескольких дней.
   *              Доступ к методу разрешен любому авторизованному пользователю без учёта групп доступа.
   *              Загруженные файлы получают флаг «временный», прикрепляются к пользователю и доступны всем
   *              пользователям аккаунта.
   *              Метод доступен для любого авторизованного пользователя.
   */
  public uploadFile(file: File): Observable<IUploadFileResponse[]> {
    const urn: string = this.createUri(`/file`);
    const formData = new FormData();
    const headers: HttpHeaders = new HttpHeaders();
    let params: HttpParams = new HttpParams();

    formData.append('file', file, file.name);
    const request = new HttpRequest(
      'POST', urn, formData,
      {
        reportProgress: true,
        headers: headers,
        params: params,
      },
    );
    return this.http
      .request<IUploadFileResponse[]>(request)
      .pipe(
        catchError((error: any): Promise<never> => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Переданы не верные данные.',
                true
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.');
            default:
              return this.handleError(error, 'Сервер вернул неожиданную ошибку: ' + error.status.toString());
          }
        }),
        map((response: HttpEvent<IUploadFileResponse[]>): any => (response as any).body),
        debounceTime(200),
      );
  }
}
