import { Injectable } from '@angular/core';

import { ISearchRequest } from "./search.types";
import { IRequestDataSearchRequest, IRequestDataSearchResponse, RequestDataRestApiService } from "../api";
import { lastValueFrom } from "rxjs";


/**
 * Отправки запроса поиска товара через оператора.
 */
@Injectable({
  providedIn: 'root'
})
export class SearchService {
  /** Конструктор. */
  constructor(
    private requestDataApiService: RequestDataRestApiService,
  ) {
  }

  /**
   * Отправка на сервер данных формы поиска товара через оператора.
   * @param req - Данные об искомо товаре.
   */
  public SearchRequestByData(req: ISearchRequest): Promise<void> {
    return new Promise<void>((
      resolve: (value: (void | PromiseLike<void>)) => void,
      reject: (reason?: any) => void,
    ): void => {
      const data: IRequestDataSearchRequest = {
        text: req.text,
        file: req.file,
      };
      lastValueFrom(this.requestDataApiService.search(data))
        .then((_: IRequestDataSearchResponse | null): void => resolve(void data))
        .catch((e: unknown): void => reject(e));
    });
  }
}
