import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ApiConfig } from "../api.types";
import { ApiService } from "../api.service";
import { ITelegramAppdataCheckRequest, ITelegramAppdataCheckResponse } from "./telegram-rest-api.types";


const zeroTime: string = '0001-01-01T00:00:00Z';

@Injectable({
  providedIn: 'root',
})
export class TelegramRestApiService extends ApiService {
  /**
   * Конструктор.
   * @param http - HTTP клиент.
   * @param config - Конфигурация API.
   */
  constructor(
    http: HttpClient,
    config: ApiConfig,
  ) {
    super(http, config);
  }

  /**
   * Проверка данных пользователя присланных в мини-апп.
   * @param req    - Данные запроса.
   * @returns      - Пользователь телеграм и установленные сессионные "печеньки".
   * @description  - Метод выполняет проверку данных о пользователе телеграм, которые высылает сервер телеграм в
   *                 мини-приложение при его открытии в клиенте телеграм. В некоторых случаях, а именно, при
   *                 открытии мини-приложения по встраиваемой кнопке, или кнопке клавиатуры, или по ссылке, телеграм
   *                 не высылает полные данные о пользователе, достаточные для их проверки телеграм ботом.
   *                 В таких случаях, используется одноразовый сессионный токен, который всегда добавляется в кнопку
   *                 или ссылку в телеграм боте, и позволяет уже на стороне бек-энда идентифицировать пользователя
   *                 телеграм.
   *                 В случае успешной проверки данных о пользователе телеграм, любым из способов, возвращаются
   *                 данные о пользователе, а так же выставляются «печеньки» с сессионным токеном доступа для
   *                 мини-приложения. По выставленным «печенькам» происходит авторизация всех последующих запросов
   *                 от мини-приложения к бек-энд серверу через API.
   *                 ВНИМАНИЕ! Данный метод вызывается без авторизации.
   */
  public appdataCheck(req: ITelegramAppdataCheckRequest): Observable<ITelegramAppdataCheckResponse> {
    const headers: HttpHeaders = new HttpHeaders();
    let urn: string = this.createUri('/telegram/appdata/check');
    return this.http
      .post<ITelegramAppdataCheckResponse>(urn, req, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response: any): ITelegramAppdataCheckResponse => response.body),
        catchError((error: any): Promise<never> => {
          switch (error.status) {
            case 427:
              return this.handleError(
                error,
                'Полученные данные пользователя телеграм не прошли проверку.',
                true,
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.');
            default:
              return this.handleError(error, 'Сервер вернул неожиданную ошибку: ' + error.status.toString());
          }
        })
      );
  }
}
