import { NgModule, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TableResizeDirective } from "./table-resize.directive";


@NgModule({
  declarations: [
    TableResizeDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    TableResizeDirective,
  ],
})
export class TableResizeModule { }
